import TrackEvent, {
  TrackEventParams,
} from "@hornet-web-react/core/models/track-event"
import GoogleAnalyticsService, { GA_DIMENSIONS } from "./GoogleAnalyticsService"
import { type AppConfig } from "./AppConfig"
import { debug, runOnClientOnly, isClient } from "@hornet-web-react/core/utils"
import GoogleAnalyticsEvent from "@hornet-web-react/core/models/google-analytics-event"
import {
  DeviceId,
  Locale,
  SessionQueryParamName,
  ServerSessionAnalytics,
} from "@hornet-web-react/core/types/session"
import LocalStorageService, {
  LocalStorageAnalytics,
  StorageKey,
} from "./LocalStorageService"
import { getCookie } from "typescript-cookie"
import { CookieName } from "@hornet-web-react/core/types"
import { isInstalledAsPwa } from "@hornet-web-react/core/utils/is-installed-as-pwa"

interface EventDebuggerData {
  hornet_tracker_id: string
  environment: string
  event: string
  event_properties: TrackEventParams
  hornet_user_profile_id?: string
  hornet_device_id?: string
}

export interface EventTrackerServiceContext {
  isInApp: boolean
  deviceId: DeviceId
  profileId: string | null
  sessionQueryParams: URLSearchParams
  locale: Locale
  serverSessionAnalytics: ServerSessionAnalytics
}

class EventTrackerService {
  private readonly _appConfig: AppConfig
  private readonly _googleAnalyticsService: GoogleAnalyticsService
  private readonly _localStorageService: LocalStorageService
  private _context: EventTrackerServiceContext
  private _reportedSessionStart = false
  private _reportedPageLoadedOnce = false
  private _isReady = false
  buffer: {
    (): void
  }[] = []

  constructor(
    appConfig: AppConfig,
    googleAnalyticsService: GoogleAnalyticsService,
    localStorageService: LocalStorageService,
    context: EventTrackerServiceContext
  ) {
    debug(`EventTrackerService: constructor`)

    this._appConfig = appConfig
    this._googleAnalyticsService = googleAnalyticsService
    this._localStorageService = localStorageService
    this._context = context

    runOnClientOnly(() => {
      void this.reportSessionStart()
    })
  }

  updateContext(context: EventTrackerServiceContext) {
    // debug(`EventTrackerService: updateContext: ${JSON.stringify(context)}`)
    this._context = context
  }

  async report(event: TrackEvent, hasAlsoGoogleAnalytics = true) {
    // report in client only
    if (!isClient) {
      return
    }

    const hornetTrackerEnvironments = ["alpha", "production"]

    const [name, params] = event.toHornetAnalytics(
      this._context.isInApp,
      this._context.locale,
      this._context.sessionQueryParams.get(SessionQueryParamName.Platform) ??
        undefined
    )

    const eventData: EventDebuggerData = {
      hornet_tracker_id: this._appConfig.eventTracking.trackerName,
      environment: this._appConfig.environment,
      event: name,
      event_properties: params,
    }

    if (this._context.profileId) {
      eventData.hornet_user_profile_id = this._context.profileId
    }

    if (this._context.deviceId) {
      eventData.hornet_device_id = this._context.deviceId
    }

    eventData.event_properties.push({
      key: "is_pwa",
      string_value: isInstalledAsPwa() ? "yes" : "no",
    })

    if (this._context.serverSessionAnalytics.country) {
      eventData.event_properties.push({
        key: "s_country",
        string_value: this._context.serverSessionAnalytics.country.toString(),
      })
    }

    // add analytics stuff from localstorage
    const analytics = this._localStorageService.getItem<LocalStorageAnalytics>(
      StorageKey.analytics
    )

    if (analytics.utmCampaign) {
      eventData.event_properties.push({
        key: "utm_campaign",
        string_value: analytics.utmCampaign,
      })
    }

    if (analytics.utmMedium) {
      eventData.event_properties.push({
        key: "utm_medium",
        string_value: analytics.utmMedium,
      })
    }

    if (analytics.utmSource) {
      eventData.event_properties.push({
        key: "utm_source",
        string_value: analytics.utmSource,
      })
    }

    if (analytics.utmTerm) {
      eventData.event_properties.push({
        key: "utm_term",
        string_value: analytics.utmTerm,
      })
    }

    if (analytics.utmKeyword) {
      eventData.event_properties.push({
        key: "utm_keyword",
        string_value: analytics.utmKeyword,
      })
    }

    if (analytics.referralId) {
      eventData.event_properties.push({
        key: "ref_id",
        string_value: analytics.referralId,
      })
    }

    // also check for cookie with X-Referrer
    if (getCookie(CookieName.XReferrer)) {
      eventData.event_properties.push({
        key: "x_ref",
        string_value: getCookie(CookieName.XReferrer),
      })
    }

    if (hornetTrackerEnvironments.includes(this._appConfig.environment)) {
      try {
        await fetch(this._appConfig.eventTracking.url, {
          method: "POST",
          headers: [
            ["Content-Type", "application/json; charset=UTF-8"],
            ["Accept", "text/plain"],
          ],
          body: JSON.stringify(eventData),
        })
      } catch (err) {
        // oops, no tracking I guess
      }
    } else {
      debug(`EventTrackerService: report: ${JSON.stringify(eventData)}`)
    }

    // also report to GA4
    if (hasAlsoGoogleAnalytics) {
      const ga4Event = GoogleAnalyticsEvent.createFromAnalyticsEvent(
        eventData.event,
        eventData.event_properties
      )

      this.reportToGoogleAnalyticsOnly(ga4Event)
    }
  }

  reportToGoogleAnalyticsOnly(
    googleAnalyticsEvent: GoogleAnalyticsEvent,
    dimensions?: Partial<GA_DIMENSIONS>
  ) {
    // report in client only
    if (!isClient) {
      return
    }

    // debug purposes - post to alpha tracker, so we can debug
    if (this._appConfig.environment === "alpha") {
      const eventData: EventDebuggerData = {
        hornet_tracker_id: this._appConfig.eventTracking.trackerName,
        environment: "alpha",
        event: "GoogleAnalyticsEvent",
        event_properties: [
          { key: "category", string_value: googleAnalyticsEvent.category },
          { key: "action", string_value: googleAnalyticsEvent.action },
          { key: "label", string_value: googleAnalyticsEvent.label },
        ],
      }

      if (Number.isInteger(googleAnalyticsEvent.value)) {
        eventData.event_properties.push({
          key: "value",
          int_value: Number(googleAnalyticsEvent.value),
        })
      }

      if (this._context.profileId) {
        eventData.hornet_user_profile_id = this._context.profileId
      }

      fetch(this._appConfig.eventTracking.url, {
        method: "POST",
        headers: [
          ["Content-Type", "application/json; charset=UTF-8"],
          ["Accept", "text/plain"],
        ],
        body: JSON.stringify(eventData),
      }).catch(() => {
        // oops, no tracking I guess
      })
    }

    // set GA dimensions if provided
    if (typeof dimensions !== "undefined") {
      this._googleAnalyticsService.setDimensions(dimensions)
    }

    // report to GA
    this._googleAnalyticsService.trackEvent(googleAnalyticsEvent)
  }

  async reportSessionStart() {
    if (!this._isReady) {
      this.buffer.push(this.reportSessionStart.bind(this))
      return
    }

    // no session to be reported if there is no profile id
    if (!this._context.profileId || this._reportedSessionStart) {
      return
    }

    debug(
      `EventTrackerService: reportSessionStart, profileId: ${this._context.profileId}`
    )

    await this.report(TrackEvent.sessionStart())
    this._reportedSessionStart = true
  }

  async reportPageLoadedOnce(
    routerPathname: string,
    hasPremium: boolean,
    extraParams?: TrackEventParams
  ) {
    if (!this._isReady) {
      this.buffer.push(
        this.reportPageLoadedOnce.bind(
          this,
          routerPathname,
          hasPremium,
          extraParams
        )
      )
      return
    }

    if (this._reportedPageLoadedOnce) {
      return
    }

    debug(`EventTrackerService: reportPageLoadedOnce: ${routerPathname}`)

    let event = TrackEvent.pageLoaded(routerPathname, hasPremium)
    if (extraParams) {
      event = event.addParams(extraParams)
    }

    await this.report(event)
    this._reportedPageLoadedOnce = true
  }

  turnOn() {
    debug(`EventTrackerService: turnOn`)

    this._isReady = true

    // call anything that hasn't processed yet
    this.buffer.forEach((fn) => fn())
  }
}

export default EventTrackerService
